export const depositIcon = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.97 1.33105H18.03C16.76 1.33105 16 2.09105 16 3.36105V6.30105C16 7.57105 16.76 8.33105 18.03 8.33105H20.97C22.24 8.33105 23 7.57105 23 6.30105V3.36105C23 2.09105 22.24 1.33105 20.97 1.33105ZM21.19 4.64105C21.07 4.76105 20.91 4.82105 20.75 4.82105C20.59 4.82105 20.43 4.76105 20.31 4.64105L20.13 4.46105V6.70105C20.13 7.05105 19.85 7.33105 19.5 7.33105C19.15 7.33105 18.87 7.05105 18.87 6.70105V4.46105L18.69 4.64105C18.45 4.88105 18.05 4.88105 17.81 4.64105C17.57 4.40105 17.57 4.00105 17.81 3.76105L19.06 2.51105C19.11 2.46105 19.18 2.42105 19.25 2.39105C19.27 2.38105 19.29 2.38105 19.31 2.37105C19.36 2.35105 19.41 2.34105 19.47 2.34105C19.49 2.34105 19.51 2.34105 19.53 2.34105C19.6 2.34105 19.66 2.35105 19.73 2.38105C19.74 2.38105 19.74 2.38105 19.75 2.38105C19.82 2.41105 19.88 2.45105 19.93 2.50105C19.94 2.51105 19.94 2.51105 19.95 2.51105L21.2 3.76105C21.44 4.00105 21.44 4.40105 21.19 4.64105Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M22 8.10108V9.33108H2V7.87108C2 5.58108 3.86 3.73108 6.15 3.73108H16V6.30108C16 7.57108 16.76 8.33108 18.03 8.33108H20.97C21.37 8.33108 21.71 8.26108 22 8.10108Z"
        fill="white"
      />
      <path
        d="M2 9.33105V16.7911C2 19.0811 3.86 20.9311 6.15 20.9311H17.85C20.14 20.9311 22 19.0811 22 16.7911V9.33105H2ZM8 17.5811H6C5.59 17.5811 5.25 17.2411 5.25 16.8311C5.25 16.4211 5.59 16.0811 6 16.0811H8C8.41 16.0811 8.75 16.4211 8.75 16.8311C8.75 17.2411 8.41 17.5811 8 17.5811ZM14.5 17.5811H10.5C10.09 17.5811 9.75 17.2411 9.75 16.8311C9.75 16.4211 10.09 16.0811 10.5 16.0811H14.5C14.91 16.0811 15.25 16.4211 15.25 16.8311C15.25 17.2411 14.91 17.5811 14.5 17.5811Z"
        fill="white"
      />
    </svg>
  )
}
export const tokenmanageIcon = (props) => {
  return (
    <svg width="22" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity=".4"
        d="M4.73 18.24c.47.07 1.01.1 1.77.1h9c.75 0 1.3-.03 1.74-.09 3.35-.37 4.01-2.36 4.01-5.66v-6c0-3.3-.66-5.29-3.98-5.65-.47-.07-1.01-.1-1.77-.1h-9c-.75 0-1.3.03-1.74.1C1.41 1.3.75 3.27.75 6.58v6c0 3.3.66 5.3 3.98 5.65Z"
        fill="#fff"
      />
      <path
        d="M6 10.84h3.5c.41 0 .75.34.75.75s-.34.75-.75.75H6a.76.76 0 0 1-.75-.75c0-.4.34-.75.75-.75ZM12.9 10.6a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1 1 1 0 0 1 1.01-1ZM15.9 10.6a1 1 0 0 1 1 1 1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1ZM6 5.34h9.98c.41 0 .76.34.76.75s-.33.75-.74.75H6a.76.76 0 0 1-.75-.75c0-.4.34-.75.75-.75Z"
        fill="#fff"
      />
    </svg>
  )
}
