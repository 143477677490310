import CryptoCurrency from '@/components/common/svgs/cryptocurrency-04';
import { getTokenAmount, stepSize, tokenConverter } from '@/utils/helper';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify';

export default function TransactionHistoryModal({
    open,
    setOpen,
    selectedTransaction,
    currencyUsd
}) {
    function closeModal() {
        setOpen(false)
    }
    console.log('selectedTransaction: ', selectedTransaction);
    let toastId = 'customId'

    return (
        <Transition appear show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all">
                                <div className='flex flex-row justify-center'>
                                    <CryptoCurrency />
                                </div>
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 my-5"
                                >
                                    {selectedTransaction?.type == 'debit' ? 'Transaction Successful! You sent:' : 'You have received a transfer:'}
                                </Dialog.Title>
                                <div className="mt-12">
                                    <p className="text-lg font-medium leading-6 text-gray-900 text-start">
                                        {selectedTransaction?.type == 'debit' ? 'To' : 'From'}
                                    </p>

                                    <div className="flex flex-row justify-between my-6">
                                        <div className='flex flex-row justify-center items-center'>
                                            {selectedTransaction.to[0].protocol == 'BSV' ? (
                                                selectedTransaction.type == 'debit' ? (
                                                    <svg
                                                        width="36"
                                                        height="36"
                                                        viewBox="0 0 36 36"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            width="36"
                                                            height="36"
                                                            rx="12"
                                                            fill="#FBE6EC"
                                                        />
                                                        <path
                                                            d="M26.97 7H24.03C22.76 7 22 7.76 22 9.03V11.97C22 13.24 22.76 14 24.03 14H26.97C28.24 14 29 13.24 29 11.97V9.03C29 7.76 28.24 7 26.97 7ZM27.19 10.31C27.07 10.43 26.91 10.49 26.75 10.49C26.59 10.49 26.43 10.43 26.31 10.31L26.13 10.13V12.37C26.13 12.72 25.85 13 25.5 13C25.15 13 24.87 12.72 24.87 12.37V10.13L24.69 10.31C24.45 10.55 24.05 10.55 23.81 10.31C23.57 10.07 23.57 9.67 23.81 9.43L25.06 8.18C25.11 8.13 25.18 8.09 25.25 8.06C25.27 8.05 25.29 8.05 25.31 8.04C25.36 8.02 25.41 8.01 25.47 8.01C25.49 8.01 25.51 8.01 25.53 8.01C25.6 8.01 25.66 8.02 25.73 8.05C25.74 8.05 25.74 8.05 25.75 8.05C25.82 8.08 25.88 8.12 25.93 8.17C25.94 8.18 25.94 8.18 25.95 8.18L27.2 9.43C27.44 9.67 27.44 10.07 27.19 10.31Z"
                                                            fill="#FE2C3D"
                                                        />
                                                        <path
                                                            opacity="0.4"
                                                            d="M28 13.77V15H8V13.54C8 11.25 9.86 9.40002 12.15 9.40002H22V11.97C22 13.24 22.76 14 24.03 14H26.97C27.37 14 27.71 13.93 28 13.77Z"
                                                            fill="#FC9BA6"
                                                        />
                                                        <path
                                                            d="M8 15V22.46C8 24.75 9.86 26.6 12.15 26.6H23.85C26.14 26.6 28 24.75 28 22.46V15H8ZM14 23.25H12C11.59 23.25 11.25 22.91 11.25 22.5C11.25 22.09 11.59 21.75 12 21.75H14C14.41 21.75 14.75 22.09 14.75 22.5C14.75 22.91 14.41 23.25 14 23.25ZM20.5 23.25H16.5C16.09 23.25 15.75 22.91 15.75 22.5C15.75 22.09 16.09 21.75 16.5 21.75H20.5C20.91 21.75 21.25 22.09 21.25 22.5C21.25 22.91 20.91 23.25 20.5 23.25Z"
                                                            fill="#FE2C3D"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        width="36"
                                                        height="37"
                                                        viewBox="0 0 36 37"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            y="0.444702"
                                                            width="36"
                                                            height="36"
                                                            rx="12"
                                                            fill="#EDEAFE"
                                                        />
                                                        <path
                                                            d="M26.97 14H24.03C22.76 14 22 13.24 22 11.97V9.03C22 7.76 22.76 7 24.03 7H26.97C28.24 7 29 7.76 29 9.03V11.97C29 13.24 28.24 14 26.97 14ZM27.19 10.69C27.07 10.57 26.91 10.51 26.75 10.51C26.59 10.51 26.43 10.57 26.31 10.69L26.13 10.87V8.63C26.13 8.28 25.85 8 25.5 8C25.15 8 24.87 8.28 24.87 8.63V10.87L24.69 10.69C24.45 10.45 24.05 10.45 23.81 10.69C23.57 10.93 23.57 11.33 23.81 11.57L25.06 12.82C25.11 12.87 25.18 12.91 25.25 12.94C25.27 12.95 25.29 12.95 25.31 12.96C25.36 12.98 25.41 12.99 25.47 12.99C25.49 12.99 25.51 12.99 25.53 12.99C25.6 12.99 25.66 12.98 25.73 12.95C25.74 12.95 25.74 12.95 25.75 12.95C25.82 12.92 25.88 12.88 25.93 12.83C25.94 12.82 25.94 12.82 25.95 12.82L27.2 11.57C27.44 11.33 27.44 10.93 27.19 10.69Z"
                                                            fill="#0075FF"
                                                        />
                                                        <path
                                                            opacity="0.4"
                                                            d="M28 13.77V15H8V13.54C8 11.25 9.86 9.40002 12.15 9.40002H22V11.97C22 13.24 22.76 14 24.03 14H26.97C27.37 14 27.71 13.93 28 13.77Z"
                                                            fill="#8FBCFE"
                                                        />
                                                        <path
                                                            d="M8 15V22.46C8 24.75 9.86 26.6 12.15 26.6H23.85C26.14 26.6 28 24.75 28 22.46V15H8ZM14 23.25H12C11.59 23.25 11.25 22.91 11.25 22.5C11.25 22.09 11.59 21.75 12 21.75H14C14.41 21.75 14.75 22.09 14.75 22.5C14.75 22.91 14.41 23.25 14 23.25ZM20.5 23.25H16.5C16.09 23.25 15.75 22.91 15.75 22.5C15.75 22.09 16.09 21.75 16.5 21.75H20.5C20.91 21.75 21.25 22.09 21.25 22.5C21.25 22.91 20.91 23.25 20.5 23.25Z"
                                                            fill="#0075FF"
                                                        />
                                                    </svg>
                                                )
                                            ) : (
                                                <img
                                                    height={36}
                                                    width={36}
                                                    objectFit="cover"
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src =
                                                            'https://relysia.com/_next/static/media/RelysiaLogo_1.4aba7d51.svg'
                                                    }}
                                                    onLoad={(e) => {
                                                        e.target.className =
                                                            'h-9 w-9 rounded-xl bg-gray-200'
                                                    }}
                                                    alt={'transaction'}
                                                    src={selectedTransaction.to[0].image}
                                                    className="h-9 w-9 rounded-xl bg-gray-200 animate-pulse"
                                                />
                                            )}
                                            <div style={{ marginInline: 16 }}>
                                                <div
                                                    className="font-bold text-lg leading-6 text-gray-900 text-start"
                                                    style={{ lineHeight: 1 }}
                                                >
                                                    {selectedTransaction.type == 'debit'
                                                        ? 'Withdrawal'
                                                        : 'Deposit'}
                                                </div>


                                                <CopyToClipboard
                                                    text={selectedTransaction.type == 'credit' ?
                                                        selectedTransaction.from
                                                        : (selectedTransaction.to[0]?.to)}
                                                    onCopy={() => {
                                                        if (toast.isActive(toastId)) {
                                                            toast.dismiss(toastId)
                                                            toastId = toastId + '2'
                                                        }
                                                        toast.success(
                                                            'Wallet Address Copied.',
                                                            {
                                                                position: 'bottom-left',
                                                                toastId: toastId,
                                                                autoClose: 10000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                            },
                                                        )
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            opacity: '0.8',
                                                            cursor: 'pointer',
                                                        }}
                                                        className="font-bold text-sm leading-6 text-gray-900 text-start"
                                                    >
                                                        {selectedTransaction.type == 'credit'
                                                            ? (selectedTransaction.from?.substring(
                                                                0,
                                                                15,
                                                            ) || '') + '..'
                                                            : (selectedTransaction.to[0]?.to.substring(
                                                                0,
                                                                15,
                                                            ) || '') + '..'}
                                                    </div>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <span
                                            rel="noopener noreferrer"
                                        >
                                            <div>
                                                <div
                                                    className="font-bold text-sm leading-6 text-gray-900 text-start"
                                                    style={{ lineHeight: 1 }}
                                                >
                                                    {new Date(
                                                        selectedTransaction.timestamp.split(' ')[0],
                                                    ).toDateString()}
                                                </div>
                                                <div style={{ opacity: '0.6' }} className='text-sm leading-6 text-gray-900 text-start'>
                                                    {selectedTransaction?.notes?.slice(0, 30)}
                                                </div>
                                            </div>
                                        </span>

                                        <div className=''>
                                            <div
                                                className="font-bold flex flex-row justify-end"
                                                style={{
                                                    lineHeight: 1,
                                                    color:
                                                        selectedTransaction.type == 'credit'
                                                            ? 'green'
                                                            : 'red',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                {selectedTransaction.type == 'debit' ? '-' : '+'}{' '}
                                                {selectedTransaction.to[0].protocol == 'BSV' ? (
                                                    tokenConverter(selectedTransaction.totalAmount)
                                                ) : (
                                                    <span className="pl-1">
                                                        {getTokenAmount(
                                                            selectedTransaction.totalAmount, selectedTransaction.to[0].tokenId
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    opacity: '0.7',
                                                    textAlign: 'right',
                                                }}
                                                className='text-gray-900'
                                            >
                                                {' '}
                                                {selectedTransaction.type == 'credit'
                                                    ? '+'
                                                    : '-'}{' '}
                                                {stepSize(
                                                    selectedTransaction.totalAmount * currencyUsd,
                                                )}
                                                $
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <p className="text-lg font-medium leading-6 text-gray-900 text-center mt-6">
                                    {`TxID: `} <a className='underline text-blue-600 cursor-pointer'
                                        target='_blank'
                                        href={`https://www.whatsonchain.com/tx/${selectedTransaction?.txId}`}

                                    >{selectedTransaction?.txId.slice(0, 25) + "..."}</a>
                                </p>
                                <button
                                    className="w-full text-white bg-blue-500 rounded-xl py-2 font-medium text-center mt-8"
                                    onClick={closeModal}
                                >
                                    Dismiss
                                </button>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
