import PropTypes from 'prop-types'

const CryptoCurrency = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
            <path d="M18 18L20 20M38 18L36 20M38 38L36 36M18 38L20 36M18 32H19.5M24 18V19.5M38 24H36.5M32 38V36.5M34 32H37.5M32 18V22M18 24H22M24 38V34M27.4343 23.5657L23.5657 27.4343C23.3677 27.6323 23.2687 27.7313 23.2316 27.8455C23.1989 27.9459 23.1989 28.0541 23.2316 28.1545C23.2687 28.2687 23.3677 28.3677 23.5657 28.5657L27.4343 32.4343C27.6323 32.6323 27.7313 32.7313 27.8455 32.7684C27.9459 32.8011 28.0541 32.8011 28.1545 32.7684C28.2687 32.7313 28.3677 32.6323 28.5657 32.4343L32.4343 28.5657C32.6323 28.3677 32.7313 28.2687 32.7684 28.1545C32.8011 28.0541 32.8011 27.9459 32.7684 27.8455C32.7313 27.7313 32.6323 27.6323 32.4343 27.4343L28.5657 23.5657C28.3677 23.3677 28.2687 23.2687 28.1545 23.2316C28.0541 23.1989 27.9459 23.1989 27.8455 23.2316C27.7313 23.2687 27.6323 23.3677 27.4343 23.5657Z" stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default CryptoCurrency
